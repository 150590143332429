import React from "react";
import "./About.css";
import Myimage from "./Untitled design (5).png";

import Homeimg from "./Home.png";
import Astra from "./pngwing.com (1).png";
import { motion } from "framer-motion";
import FooterBottom from "./FooterBottom.js";

function About() {
  return (
    <div className="home-about">
      <div className="intrdution-about">
        <div className="my-half-width-about">
          <h1 className="normal-text-about">
            Hey, I'm <span className="pink-about">JANI BASHA.!</span>
          </h1>
          <p className="paragraph-about">
            "As a front-end developer, I believe that creating intuitive and
            responsive interfaces is crucial to enhancing the user experience. I
            strive to stay up-to-date with the latest technologies and trends in
            web development to ensure that the websites and web applications I
            build are cutting-edge and meet the needs of my clients and their
            users. I am particularly skilled in using <strong>React.js</strong>,
            which has become one of the most popular front-end frameworks in
            recent years. Using React.js, I am always looking for ways to
            optimize my code and improve the performance of my applications.{" "}
            <br></br>
            In addition to building websites and web applications, I am also
            passionate about teaching others how to code. I believe that anyone
            can learn to code, regardless of their background or experience, and
            I enjoy sharing my knowledge and helping others take their first
            steps in the world of web development. I have created several online
            tutorials to help beginners learn the basics of{" "}
            <strong> HTML, CSS, and JavaScript and React.js.</strong>
            Looking ahead, I am excited to take on new projects and work with
            clients to build innovative and engaging web experiences. "
          </p>
        </div>
        <div className="my-image-half-width-about">
          <img src={Myimage} alt="my-img" />
        </div>
      </div>

      <h1 className="normal-text-work-about">
        I'm <span className="pink-about">Help On:</span>
      </h1>
      <div className="my-work-about">
        <div className="my-work-half-width-about">
          <h1 className="my-work-text-about">Web Development</h1>
          <p className="my-work-para-about">
            I can do Building user-friendly website using{" "}
            <strong>
              React.js, JavaScript, HTML, CSS, Framer Motion, Tailwind CSS and
              BootStrap.
            </strong>{" "}
            in your Website I can add text, audio, video, graphics and etc...
            with in short period of time.
          </p>
        </div>
        <div className="my-work-image-half-width-about">
          <img src={Homeimg} alt="website" />
        </div>
      </div>

      <div className="my-work-ani-about">
        <div className="my-work-ani-half-width-about">
          <h1 className="my-work-text-ani-about">Animation</h1>
          <motion.p
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 10 }}
            className="my-work-para-ani-about"
          >
            I work on Animation that is very engaging to viewer to spend more
            time in web browser using{" "}
            <strong>Framer motion , Canva, Photoshop and Blender.</strong>
          </motion.p>
        </div>
        <div className="my-work-ani-image-half-width-about">
          <motion.img
            src={Astra}
            alt="website-ani"
            className="space-about"
            initial={{ opacity: 0.4, x: 0 }}
            animate={{ opacity: 1, x: 0, rotate: 360 }}
            whileHover={{ rotate: 360, X: 0, y: 0, scale: 1.5 }}
            transition={{ duration: 30 }}
          />
        </div>
      </div>
      <FooterBottom />
    </div>
  );
}

export default About;
