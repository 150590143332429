import React from "react";
import "./Contact.css";
import { motion } from "framer-motion";
import FooterBottom from "./FooterBottom";

function Contact() {
  const datas = [
    {
      id: 1,
      heading: "Call me",
      link: "tel:+91799-342-0487",
      image: "https://cdn-icons-png.flaticon.com/512/5585/5585856.png",
      alt: "phone-image",
    },
    {
      id: 2,
      heading: "Email me",
      link: "mailto:janibashamujavar123@gmail",
      image: "https://cdn-icons-png.flaticon.com/512/732/732200.png",
      alt: "email-image",
    },
    {
      id: 3,
      heading: "Linkedin connect",
      link: "https://www.linkedin.com/in/shaik-mujavar-jani-basha-868770248",
      image: "https://cdn-icons-png.flaticon.com/512/3536/3536505.png",
      alt: "linkedin-image",
    },
    {
      id: 4,
      heading: "Whatsapp",
      link: "https://api.whatsapp.com/send?phone=7993420487",
      image: "https://cdn-icons-png.flaticon.com/512/3670/3670051.png",
      alt: "whatsapp-image",
    },
    {
      id: 5,
      heading: "Instagram",
      link: "https://instagram.com/smart_john_basha?igshid=ZDdkNTZiNTM=",
      image: "https://cdn-icons-png.flaticon.com/512/3955/3955024.png",
      alt: "instagram-image",
    },
  ];

  return (
    <div>
      <div className="contact-con">
        <h3>Contact</h3>
        <div>
          {datas.map((data) => {
            return (
              <div key={data.id}>
                <div className="contact-mobile-con">
                  <h2>{data.heading}</h2>
                  <a href={data.link}>
                    <div className="mobile-con">
                      <a href={data.link}>
                        <motion.img
                          src={data.image}
                          alt={data.alt}
                          animate={{ scale: 1.3 }}
                          whileHover={{ scale: 1.5, rotate: 45 }}
                        />
                      </a>
                    </div>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <FooterBottom />
      </div>
    </div>
  );
}

export default Contact;
