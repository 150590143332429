import React from "react";
import Logo from "./Pink Minimal Typography Logo .png";
import "./HeaderNav.css";
import { Link } from "react-router-dom";

import { FaGithub } from "react-icons/fa";

function HeaderNav() {
  return (
    <div className="nav-width">
      <div className="nav">
        <div className="nav-logo">
          <img src={Logo} alt="My_logo" />
        </div>

        <div className="nav-text">
          <Link to="/">
            <h3>About</h3>
          </Link>
          <Link to="/skills">
            <h3>Skills</h3>
          </Link>
          <Link to="education">
            <h3>Education</h3>
          </Link>
          <Link to="/projects">
            <h3>Projects</h3>
          </Link>
          <Link to="contact">
            <h3>Contact</h3>
          </Link>
        </div>

        <div className="nav-social">
          <a href="https://www.linkedin.com/in/shaik-mujavar-jani-basha-868770248">
            <div className="nav-linkedin">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3536/3536505.png"
                alt="linkedin-logo"
              />
            </div>
          </a>
          <a href="tel:+91799-342-0487">
            <div className="nav-instagram">
              <img
                src="https://cdn-icons-png.flaticon.com/512/5585/5585856.png"
                alt="instagram-logo"
              />
            </div>
          </a>
          <a href="https://github.com/janibasha00">
            <div className="nav-github">
              <FaGithub />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HeaderNav;
