import React from "react";
import "./Skill.css";
import { motion } from "framer-motion";
import FooterBottom from "./FooterBottom";

function Skills() {
  return (
    <div>
      <div className="skills-skill">
        <div className="my-half-width-skill">
          <h1 className="normal-text-skill">
            MY<span className="pink-skill"> SKILLS</span>
          </h1>
          <div className="box-skill">
            <h3 className="header-skill">Technical Skills</h3>
            <div className="react-skill">
              <h4 className="react-para-skill">REACT.JS</h4>
              <motion.img
                animate={{ opacity: 1, x: 0, rotate: 360 }}
                whileHover={{ rotate: 360, X: 0, y: 0 }}
                transition={{ duration: 20 }}
                src="https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png"
                alt="React"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Javascript</h4>
              <img
                src="https://www.freepnglogos.com/uploads/javascript-png/javascript-vector-logo-yellow-png-transparent-javascript-vector-12.png"
                alt="Javascript"
                className="react-image-skill"
              />
            </div>

            <div className="react-skill">
              <h4 className="react-para-skill">HTML</h4>
              <img
                src="https://www.freepnglogos.com/uploads/html5-logo-png/html5-logo-html-logo-0.png"
                alt="html"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">CSS</h4>
              <img
                src="https://www.freepnglogos.com/uploads/html5-logo-png/html5-logo-css-logo-png-transparent-svg-vector-bie-supply-9.png"
                alt="css"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Framer</h4>
              <img
                src="https://konstantinlebedev.com/static/76d81ec25cd799b15cd16c0b16e869af/11a8f/cover.png"
                alt="framer"
                className="framer-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Bootstrap</h4>
              <img
                src="https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png"
                alt="bootsrap"
                className="react-image-skill"
              />
            </div>
          </div>
          <div className="box-skill">
            <h3 className="header-skill">Design Skills</h3>
            <div className="react-skill">
              <h4 className="react-para-skill">Canva</h4>
              <img
                src="https://cdn-images-1.medium.com/max/1200/1*A6kkoOVJVpXPWewg8axc5w.png"
                alt="canva"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Photoshop</h4>
              <img
                src="https://seeklogo.com/images/A/adobe-photoshop-logo-7B88D7B5AA-seeklogo.com.png"
                alt="photoshop"
                className="react-image-skill"
              />
            </div>

            <div className="react-skill">
              <h4 className="react-para-skill">Premier Pro</h4>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/f/f2/Adobe_Premiere_Pro_Logo.svg"
                alt="premier-pro"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Blender</h4>
              <img
                src="https://www.nicepng.com/png/detail/343-3436343_blender-logo-png.png"
                alt="Blender"
                className="react-image-skill"
              />
            </div>
          </div>
          <div className="box-skill">
            <h3 className="header-skill">Soft Skills</h3>
            <div className="react-skill">
              <h4 className="react-para-skill">Communication</h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/1077/1077909.png"
                alt="canva"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Self-Motivation</h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/2452/2452136.png"
                alt="selfmotivation"
                className="react-image-skill"
              />
            </div>

            <div className="react-skill">
              <h4 className="react-para-skill">Leadership</h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/3287/3287549.png"
                alt="leadership"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">TeamWork</h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/5541/5541703.png"
                alt="teamwork"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Problem Solving</h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/3488/3488370.png"
                alt="problem-solving"
                className="react-image-skill"
              />
            </div>

            <div className="react-skill">
              <h4 className="react-para-skill">
                Work under<br></br> Pressure
              </h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/3264/3264753.png"
                alt="work"
                className="react-image-skill"
              />
            </div>
            <div className="react-skill">
              <h4 className="react-para-skill">Time Managment</h4>
              <img
                src="https://cdn-icons-png.flaticon.com/512/2382/2382564.png"
                alt="time-managment"
                className="react-image-skill"
              />
            </div>
          </div>
        </div>
        <FooterBottom />
      </div>
    </div>
  );
}

export default Skills;
