import React from "react";
import Todo from "./Todo list.png";
import TodoTumbnailone from "./CLONE USING.png";
import TodoTumbnailtwo from "./CLONE USING 2.png";
import Weather from "./weather.png";
import Youtube from './youtube.png';
import "./Projects.css";

function Projects() {
  return (
    <div className="projects">
      <h2 className="header-pro">
        MY <span className="pink-pro">PROJECTS</span>
      </h2>
      <div className="project">
        <h3 className="title">1) TODO LIST</h3>
        <p>Below image look likes the project output</p>

        <img src={Todo} alt="todo" />
        <h2 className="explanation-text">
          Project Explnation video created by my-self
        </h2>
        <h6>Click on image to re-direct to video</h6>

        <a href="https://www.linkedin.com/posts/shaik-mujavar-jani-basha-868770248_react-amazon-webdevelopment-activity-7019620002454536192-3FZY?utm_source=share&utm_medium=member_desktop">
          <img
            src={TodoTumbnailone}
            alt="tumbnail"
            className="todotumbnailone"
          />
        </a>
        <a href="https://www.linkedin.com/posts/shaik-mujavar-jani-basha-868770248_developer-react-amazon-activity-7020737258773254144-Mgan?utm_source=share&utm_medium=member_desktop">
          <img
            src={TodoTumbnailtwo}
            alt="tumbnail"
            className="todotumbnailtwo"
          />
        </a>

        <a href="https://csb-76011v.netlify.app/">
          <button className="button">Click Here to view Project </button>
        </a>
      </div>
      <div className="project">
        <h3 className="title">2) YOUTUBE CLONE</h3>
        <p>Below image look likes the project output</p>

        <img src={Youtube} alt="Youtube" />

        <a href="https://youtube.janibasha.com/">
          <button className="button">Click Here to view Project </button>
        </a>
      </div>
      <div className="project">
        <h3 className="title">3) WEATHER APP</h3>
        <p>Below image look likes the project output</p>

        <img src={Weather} alt="weather" />

        <a href="https://csb-m7iv16.netlify.app/">
          <button className="button">Click Here to view Project </button>
        </a>
      </div>
    </div>
  );
}

export default Projects;
