import React from "react";
import "./Education.css";
import FooterBottom from "./FooterBottom";

function Education() {
  return (
    <div>
      <div className="education-edu">
        <div>
          <h3 className="header-edu">
            MY <span className="pink">EDUCATION</span>
          </h3>
        </div>
        <div className="box-edu">
          <h2 className="edu-year-edu">2016</h2>
          <h4 className="edu-para-edu">
            In 2016, I completed my <strong>SSC</strong> at Zilla Parishad High
            School, Gundlapalli with a First-Class Grade.
          </h4>
        </div>

        <div className="box-edu">
          <h2 className="edu-year-edu">2019</h2>
          <h4 className="edu-para-edu">
            In 2019, I completed my{" "}
            <strong>Diploma In Civil Engineering</strong> at SMVM polytechnic
            College, Tanuku with a First-Class Grade.
          </h4>
        </div>

        <div className="box-edu">
          <h2 className="edu-year-edu">2022</h2>
          <h4 className="edu-para-edu">
            In 2022, I completed my{" "}
            <strong>Graduation In Civil Engineering</strong> at Narasaropet
            Engineering College, Narasaropet with a 7.81 CGPA.
          </h4>
        </div>
        <div className="box-edu">
          <h2 className="edu-year-edu">Note:</h2>
          <h4 className="edu-para-edu">
            I completed my Graduation in Civil Engineering, but my passion lies
            on in <strong>Web development.</strong> To fullfill my passion on
            web development, i have taken a course in front-end development and
            have acquired skills in front-end development through project-based
            learning. i also creating Website for my friends and clients, and
            more explaining project explanation videos on my own to new
            learners.{" "}
          </h4>
        </div>
        <FooterBottom />
      </div>
    </div>
  );
}

export default Education;
