import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import HeaderNav from "./Components/HeaderNav";
import About from "./Components/About.js";
import Skills from "./Components/Skills";
import Education from "./Components/Education";
import Contact from "./Components/Contact";
import Projects from "./Components/Projects";
import ScrollToTop from "./ScrollToTop";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <HeaderNav />

      <ScrollToTop />
      
      <Routes>
        <Route exact path="/" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/education" element={<Education />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div> 
  );
}

export default App;
