import React from "react";
import "./FooterBottom.css";

function FooterBottom() {
  return (
    <div className="footer">
      <p>Copyright &copy; 2023 JANI BASHA</p>
    </div>
  );
}

export default FooterBottom;
